import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  DtoDto,
  DtoPropertyDto,
  PropertyTypeDto,
  DataTypeDto,
  FailedImportsViewDto,
  GetImportTemplateQueryParams,
  GetImportTemplateByidQueryParams,
  GetImportTemplateViewQueryParams,
  GetImportDataSourceQueryParams,
  GetImportDataSourceByidQueryParams,
  GetDataTypeQueryParams,
  GetImportTemplateMappingQueryParams,
  GetDtoQueryParams,
  GetDtoPropertyQueryParams,
  GetDtoPropertyByidQueryParams,
  GetPropertyTypeQueryParams,
  GetPropertyTypeByidQueryParams,
  GetFailedImportsViewQueryParams,
  GetImportByidQueryParams,
  GetImportByidFileQueryParams,
  ImportDataSourceAllowedKeyFieldsDto,
  GetImportDataSourceAllowedKeyFieldsQueryParams,
  ImportDataSourceDto,
  ImportTemplateDto,
  ImportTemplateMappingDto,
  ImportTemplateViewDto
} from 'src/models/import';
import { QueryProps, baseQueryFn } from 'src/queries/baseQueryFunctions';
import {
  getDtoProperties,
  getDtoPropertyById,
  getDtos,
  getFailedImportsViews,
  getImportById,
  getImportDataSourceAllowedKeyFields,
  getImportDataSourceById,
  getImportDataSources,
  getImportFile,
  getImportMappingDataTypes,
  getImportTemplateById,
  getImportTemplateMappings,
  getImportTemplateViews,
  getImportTemplates,
  getPropertyTypeById,
  getPropertyTypes
} from 'src/services/import';

/**
 * Import Templates Query
 */
export const useGetImportTemplatesQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<ImportTemplateDto[], GetImportTemplateQueryParams>): UseQueryResult<ImportTemplateDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importTemplates', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportTemplates(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Import Template By Id Query
 */
export const useGetImportTemplateByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData,
    onSuccess = undefined,
    onError = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<ImportTemplateDto, GetImportTemplateByidQueryParams>
): UseQueryResult<ImportTemplateDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importTemplateById', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportTemplateById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Import Template Views Query
 */
export const useGetImportTemplateViewsQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<ImportTemplateViewDto[], GetImportTemplateViewQueryParams>): UseQueryResult<ImportTemplateViewDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importTemplateViews', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportTemplateViews(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Import Data Sources Query
 */
export const useGetImportDataSourcesQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<ImportDataSourceDto[], GetImportDataSourceQueryParams>): UseQueryResult<ImportDataSourceDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importDataSources', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportDataSources(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Import Data Source By Id Query
 */
export const useGetImportDataSourceByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData,
    onSuccess = undefined,
    onError = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<ImportDataSourceDto, GetImportDataSourceByidQueryParams>
): UseQueryResult<ImportDataSourceDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importDataSourceById', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportDataSourceById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Import Mapping Data Types Query
 */
export const useGetImportMappingDataTypeQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<DataTypeDto[], GetDataTypeQueryParams>): UseQueryResult<DataTypeDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importMappingDataTypes', ...Object.values(params)],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportMappingDataTypes(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Import Template Mappings Query
 */
export const useGetImportTemplateMappingsQuery = ({
  enabled = true,
  initialData,
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<ImportTemplateMappingDto[], GetImportTemplateMappingQueryParams>): UseQueryResult<
  ImportTemplateMappingDto[]
> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importTemplateMappings', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportTemplateMappings(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Get DTOs Query
 */
export const useGetDtosQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<DtoDto[], GetDtoQueryParams>): UseQueryResult<DtoDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['import-dtos-sync', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getDtos(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * DTO Properties Query
 */
export const useGetDtoPropertiesQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<DtoPropertyDto[], GetDtoPropertyQueryParams>): UseQueryResult<DtoPropertyDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['dtoProperties', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getDtoProperties(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * DTO Property By Id Query
 */
export const useGetDtoPropertyByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData,
    onSuccess = undefined,
    onError = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<DtoPropertyDto, GetDtoPropertyByidQueryParams>
): UseQueryResult<DtoPropertyDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['dtoPropertyById', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getDtoPropertyById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Get property types query
 */
export const useGetPropertyTypesQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  params = {}
}: QueryProps<PropertyTypeDto[], GetPropertyTypeQueryParams>): UseQueryResult<PropertyTypeDto[]> => {
  return useQuery({
    queryKey: ['propertyTypes', customResponseModifier],
    queryFn: () => baseQueryFn({ serviceFn: () => getPropertyTypes(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Get property type by id query
 */
export const useGetPropertyTypeByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData,
    onSuccess = undefined,
    onError = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<PropertyTypeDto, GetPropertyTypeByidQueryParams>
): UseQueryResult<PropertyTypeDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['propertyTypeById', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getPropertyTypeById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Get failed import views query
 */
export const useGetFailedImportsViewsQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  params = {}
}: QueryProps<FailedImportsViewDto[], GetFailedImportsViewQueryParams>): UseQueryResult<FailedImportsViewDto[]> => {
  return useQuery({
    queryKey: ['failedImportsViews', customResponseModifier],
    queryFn: () => baseQueryFn({ serviceFn: () => getFailedImportsViews(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Get import by id query
 */
export const useGetImportByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData,
    onSuccess = undefined,
    onError = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<ImportDataSourceDto, GetImportByidQueryParams>
): UseQueryResult<ImportDataSourceDto> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importById', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Get import file by import id query
 */
export const useGetImportFileByImportIdQuery = (
  id: string,

  {
    enabled = true,
    initialData,
    onSuccess = undefined,
    onError = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<File, GetImportByidFileQueryParams>,
  partnerId?: string
): UseQueryResult<File> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importFileByImportId', id, params],
    queryFn: () => baseQueryFn({ serviceFn: () => getImportFile(id, params, partnerId), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Import Data Source Allowed Key Fields Query
 */
export const useGetImportDataSourceAllowedKeyFieldsQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<ImportDataSourceAllowedKeyFieldsDto[], GetImportDataSourceAllowedKeyFieldsQueryParams>): UseQueryResult<
  ImportDataSourceAllowedKeyFieldsDto[]
> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['importDataSourceAllowedKeyFields', params],
    queryFn: () =>
      baseQueryFn({ serviceFn: () => getImportDataSourceAllowedKeyFields(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};
