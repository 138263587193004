import { Box, IconButton, useTheme } from '@mui/material';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { Close } from 'mdi-material-ui';
import { useEffect, useState } from 'react';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import VigoFileDrop, { VigoAttachment } from 'src/@vigo/vigo-filedrop';
import { useVigoToast } from 'src/@core/hooks/useVigoToast';
import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';
import { useAttachmentPanel } from 'src/@core/hooks/useAttachmentPanel';
import { onPanelClose, onPanelOpen } from 'src/@core/utils/panelEvents';
import { useAppSelector } from 'src/store/baseHooks';
import { useGetAttachmentsQuery } from 'src/queries/services/attachments';
import { AttachmentDto } from 'src/models/attachment';
import { getFile } from 'src/services/attachment';
import { FileRejection } from 'react-dropzone';
import Badge from '@mui/material/Badge';
import { Paperclip } from 'mdi-material-ui';
import { useAttachmentPanelMutations } from 'src/views/uiflow/warehouse/shared/panels/attachmentPanel/mutations';

const MAX_FILE_SIZE = 10; // Megabytes

const Drawer = styled(MuiDrawer)<DrawerProps>(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  '& .MuiDrawer-paper': {
    border: 0,
    width: '30%',
    minWidth: '300px',
    top: '50px',
    height: '96%',
    zIndex: theme.zIndex.modal,
    boxShadow: theme.shadows[9]
  }
}));

const AttachmentsPanel = () => {
  // State
  const [attachmentData, setAttachmentData] = useState<any[]>([]);
  const [selectedPaperWorkType, setSelectedPaperWorkType] = useState<any>(null);
  const [attachmentsOpen, setAttachmentsOpen] = useState<boolean>(false);

  // Hooks
  const { t } = useVigoTranslation();
  const { vigoToast } = useVigoToast({});
  const {
    setAttacmentCount,
    attachmentCount,
    attachmentsAllowed,
    allowedAttachmentTypes = [],
    attachmentTypes = []
  } = useAttachmentPanel();
  const theme = useTheme();

  // Selectors
  const attachmentDtoId = useAppSelector(state => state.attachmentsData.attachmentDtoId);
  const selectedEntityId = useAppSelector(state => state.attachmentsData.selectedEntityId);

  /**
   * Get entity attachments
   */
  const {} = useGetAttachmentsQuery({
    params: { filter: `entityId eq guid'${selectedEntityId}'` },
    initialData: [],
    enabled: Boolean(selectedEntityId) && attachmentsAllowed && attachmentsOpen,
    onSuccess: res => {
      try {
        const requests = res.map((attachment: AttachmentDto) => getFile(attachment.id!, { ignoreOwner: true }));

        Promise.all(requests)
          .then(values => {
            const files =
              values?.map((value, index) => {
                const newFile = new File([value.data], res[index].name!, {
                  type: res[index].fileType!
                });

                Object.defineProperties(newFile, {
                  attachmentDtoId: {
                    value: res?.[index]?.id ?? '',
                    writable: true,
                    configurable: true
                  },
                  attachmentTypeId: {
                    value: res?.[index]?.attachmentTypeId ?? '',
                    writable: true,
                    configurable: true
                  },
                  attachmentVersion: {
                    value: res?.[index]?.version,
                    writable: true,
                    configurable: true
                  },
                  uploaded: {
                    value: true,
                    writable: true,
                    configurable: true
                  },
                  ...(Object.hasOwn(res?.[index] ?? {}, 'thumbnail') && {
                    thumbnail: {
                      value: res?.[index]?.thumbnail ?? '',
                      writable: true,
                      configurable: true
                    }
                  })
                });

                return newFile;
              }) ?? [];

            setAttachmentData(files);
          })
          .catch(() => {
            vigoToast.error(t('Error retrieving one or more attachments'));
          });
      } catch (error) {
        vigoToast.error(t('Error retrieving one or more attachments'));
      }
    }
  });

  // MUTATIONS
  const attachmentPanelMutations = useAttachmentPanelMutations();
  const { deleteAttachmentMutation } = attachmentPanelMutations;

  /**
   * Attachment types filterd by what is allowed
   */
  const attachmentTypeData = attachmentTypes.filter(type => {
    return allowedAttachmentTypes.some(allowedType => allowedType.attachmentTypeId === type.id);
  });

  /**
   * Files uploaded
   */
  const filesUploaded = (acceptedFiles: VigoAttachment[]) => {
    setAttachmentData(acceptedFiles);
  };

  /**
   * On paper work change
   */
  const onPaperWorkChange = (event: any) => {
    //console.log('onPaperWorkChange', event.target.value);
    setSelectedPaperWorkType(event.target.value);
  };

  /**
   * On file delete
   */
  const onFileDelete = (file: any) => {
    if (file.uploaded) {
      deleteAttachmentMutation.mutateAsync(file);
    }
  };

  /**
   * Handle displaying error toast messges for file rejection errors.
   * @param rejectedFiles
   */
  const handleFileRejections = (rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      for (const { errors, file } of rejectedFiles) {
        if (errors[0].code === 'file-too-large') {
          return vigoToast.error(`${errors[0].code}:\n ${file.name} is larger than ${MAX_FILE_SIZE}MB`);
        }

        if (errors[0].code === 'file-invalid-type') {
          return vigoToast.error(`${errors[0].code}:\n ${file.name} is an invalid file type`);
        }

        vigoToast.error(`${errors[0].code}:\n ${errors[0].message}`);
      }
    }
  };

  /**
   * Get text color based on theme.
   *
   * @returns string
   */
  const textColor = (): string => {
    return `rgba(${theme.palette.customColors.dark}, 0.87)`;
  };

  /**
   * Handle close event.
   *
   * @param e
   * @param reason
   */
  const handleClose = (e: Event, reason: string): void => {
    if (reason === 'backdropClick') {
      setAttachmentsOpen(false);
    }
  };

  useEffect(() => {
    /**
     * Update attachment count when attachments are received.
     */
    if (attachmentData.length < 0) {
      return;
    }

    setAttacmentCount({
      type: 'setAttachmentCount',
      payload: attachmentData.length > 0 ? attachmentData.length : 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentData]);

  useEffect(() => {
    /**
     * Reset states if selectedEntityId changes
     */
    setAttachmentData([]);
  }, [selectedEntityId]);

  useEffect(() => {
    /**
     * Fire panel event on change of 'open' prop.
     */
    attachmentsOpen ? onPanelOpen({ panelName: 'attachmentsPanel' }) : onPanelClose({ panelName: 'attachmentsPanel' });
  }, [attachmentsOpen]);

  return (
    <>
      <IconButton
        sx={{
          color: `${Boolean(selectedEntityId) && attachmentsAllowed ? textColor() : theme.palette.vigoColors.gray}`,
          cursor: `${Boolean(selectedEntityId) && attachmentsAllowed ? 'pointer' : 'default'}`
        }}
        onClick={() => (Boolean(selectedEntityId) && attachmentsAllowed ? setAttachmentsOpen(prev => !prev) : null)}
        disableRipple={!(Boolean(selectedEntityId) && attachmentsAllowed)}>
        <Badge badgeContent={Boolean(selectedEntityId) && attachmentsAllowed ? attachmentCount : 0} color="secondary">
          <Paperclip id="attachmentsbutton" />
        </Badge>
      </IconButton>
      <Drawer
        open={attachmentsOpen}
        anchor="right"
        sx={{ '.MuiModal-backdrop': { backgroundColor: 'transparent !important' } }}
        onClose={handleClose}>
        <Box
          className="customizer-header"
          sx={{
            position: 'relative',
            p: theme => theme.spacing(3.5, 5),
            borderBottom: theme => `1px solid ${theme.palette.divider}`
          }}>
          <VigoTypography value="Attachments" variant="h6" sx={{ fontWeight: 600, textTransform: 'uppercase' }} />
          <IconButton
            onClick={() => setAttachmentsOpen(false)}
            sx={{
              right: 20,
              top: '50%',
              position: 'absolute',
              color: 'text.secondary',
              transform: 'translateY(-50%)'
            }}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ padding: '15px' }}>
          <VigoFileDrop
            maxFileSizeInMB={MAX_FILE_SIZE} // Megabytes
            dropBoxHeight="200px"
            acceptedFileTypes={{
              'image/*': ['.png', '.tiff', '.jpg', '.jpeg'],
              'application/pdf': ['.pdf']
            }}
            entityId={selectedEntityId}
            entityTypeId={attachmentDtoId}
            attachmentTypes={attachmentTypeData}
            description="Drag & drop paperwork here"
            files={attachmentData}
            onChange={(acceptedFiles: VigoAttachment[], rejectedFiles: FileRejection[]) => {
              filesUploaded(acceptedFiles);
              handleFileRejections(rejectedFiles);
            }}
            {...{
              onFileDelete,
              selectedPaperWorkType,
              onPaperWorkChange
            }}
            allowToggleFileVisibility={false}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default AttachmentsPanel;
