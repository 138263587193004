import { AxiosRequestConfig } from 'axios';
import {
  DepartmentDto,
  CompanyDto,
  CompanyModel,
  ContactDto,
  ContactModel,
  CustomerStatusDto,
  GetContactQueryParams,
  GetSiteQueryParams,
  RelationshipListItemDto,
  RelationshipModel,
  SiteDto,
  SiteModel,
  SiteStatusDto,
  SiteTypeDto,
  GeneralConfigProfileDto,
  GetGeneralConfigProfileQueryParams,
  GetGeneralConfigProfileByidQueryParams,
  GeneralConfigProfileModel,
  GeneralConfigProfileDtoApiResponse,
  GetCustomerQueryParams,
  CustomerDto,
  GetPrintingTypeQueryParams,
  PrintingTypeDto,
  PrintingDefaultModelBulkItemModel,
  PrintingDefaultDtoApiResponseListApiResponse,
  GetPrintingDefaultQueryParams,
  PrintingDefaultDto,
  GetSalesOrderCutOffProfileQueryParams,
  SalesOrderCutOffProfileDto,
  GetSalesOrderCutOffProfileByidQueryParams,
  SalesOrderCutOffProfileModel,
  SalesOrderCutOffProfileDtoApiResponse,
  GetOperationalProfileQueryParams,
  OperationalProfileDto,
  GetOperationalProfileByidQueryParams,
  GetUnitCodeProfileQueryParams,
  UnitCodeProfileDto,
  GetOperationalProfileUnitCodeProfileQueryParams,
  OperationalProfileUnitCodeProfileDto,
  OperationalProfileModel,
  OperationalProfileDtoApiResponse,
  OperationalProfileUnitCodeProfileModelBulkItemModel,
  OperationalProfileUnitCodeProfileDtoApiResponseListApiResponse,
  GetCompanyByidQueryParams,
  GetContactByidQueryParams,
  GetSurchargeTypeQueryParams,
  SurchargeTypeDto,
  GetDefaultSurchargeQueryParams,
  DefaultSurchargeDto,
  GetCustomerByidQueryParams,
  GetOrderTypeQueryParams,
  OrderTypeDto,
  GetOrderTypeCutOffQueryParams,
  OrderTypeCutOffDto,
  OrderTypeCutOffModelBulkItemModel,
  OrderTypeCutOffDtoApiResponseListApiResponse,
  GetCompanyQueryParams,
  GetHazardousGoodsProfileQueryParams,
  HazardousGoodsProfileDto,
  GetHazardousGoodsProfileByidQueryParams,
  HazardousGoodsProfileModel,
  HazardousGoodsProfileDtoApiResponse,
  GetHazardousGoodsClassQueryParams,
  HazardousGoodsClassDto,
  GetAvailableHazardousGoodsClassQueryParams,
  AvailableHazardousGoodsClassDto,
  AvailableHazardousGoodsClassModelBulkItemModel,
  AvailableHazardousGoodsClassDtoApiResponseListApiResponse,
  GetUnitOfMeasureQueryParams,
  UnitOfMeasureDto,
  GetHazardousMeasureQueryParams,
  HazardousMeasureDto,
  HazardousMeasureModelBulkItemModel,
  HazardousMeasureDtoApiResponseListApiResponse,
  GetUnitCategoryQueryParams,
  UnitCategoryDto,
  GetHazardousTypeQueryParams,
  HazardousTypeDto,
  HazardousTypeModelBulkItemModel,
  HazardousTypeDtoApiResponseListApiResponse,
  GetRelationshipQueryParams,
  CustomerModelBulkItemModel,
  CustomerDtoApiResponseListApiResponse,
  GetSiteByidQueryParams,
  GetSalesOrderConfigurationBycustomerIdQueryParams,
  SalesOrderConfigurationDto,
  GetSalesOrderReferenceTypeQueryParams,
  SalesOrderReferenceTypeDto,
  CustomerProfilesDto,
  PrintingDefaultModel,
  PrintingDefaultDtoApiResponse,
  PatchCustomerQueryParams,
  GetCustomerStatusQueryParams,
  GetSiteStatusQueryParams,
  RelationshipDtoApiResponse,
  RelationshipDto,
  GetSiteTypeQueryParams,
  SiteDtoApiResponse,
  CompanyDtoApiResponse
} from 'src/models/relationshipmanagement';
import { GetAxiosInstance } from '../baseService';
import { ServiceLocation } from '../serviceLocationsAxios';

// Get Relationship View
export const getRelationshipView = async (params?: GetRelationshipQueryParams) => {
  return GetAxiosInstance().get<RelationshipListItemDto[]>(ServiceLocation.relationshipmanagement + '/relationship', {
    params
  });
};

/**
 * Get Customers
 * @param params GetCustomerQueryParams
 * @returns CustomersView
 */
export const getCustomers = async (params?: GetCustomerQueryParams) => {
  return GetAxiosInstance().get<CustomerDto[]>(ServiceLocation.relationshipmanagement + '/customer', { params });
};

/**
 * Get Customers Profiles
 * @param params GetCustomerQueryParams
 * @returns CustomersView
 */
export const getCustomersProfiles = async () => {
  return GetAxiosInstance().get<CustomerProfilesDto[]>(ServiceLocation.relationshipmanagement + '/customer/profiles');
};

/**
 * Get Customer by Id
 * @param id Id of the Customer
 * @param params GetCustomerByidQueryParams
 * @returns CustomerDto
 */
export const getCustomerById = async (id: string, params?: GetCustomerByidQueryParams) => {
  return GetAxiosInstance().get<CustomerDto>(ServiceLocation.relationshipmanagement + '/Customer/' + id, { params });
};

// Get Customer Statuses
export const getCustomerStatuses = async (params?: GetCustomerStatusQueryParams) => {
  return GetAxiosInstance().get<CustomerStatusDto[]>(ServiceLocation.relationshipmanagement + '/customerstatus', {
    params
  });
};

// Post Relationship
export const postRelationship = async (params: RelationshipModel, tenantId?: string, partnerId?: string) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).post(
    ServiceLocation.relationshipmanagement + '/relationship',
    params
  );
};

/**
 * Get Companies
 * @param params GetCompanyQueryParams
 * @returns CompanyDto[]
 */
export const getCompanies = async (params?: GetCompanyQueryParams) => {
  return GetAxiosInstance().get<CompanyDto[]>(ServiceLocation.relationshipmanagement + '/Company', { params });
};

// Get Company By Id
export const getCompanyById = async (companyId: string, params?: GetCompanyByidQueryParams) => {
  return GetAxiosInstance().get<CompanyDto>(ServiceLocation.relationshipmanagement + '/company/' + companyId, {
    params
  });
};

/**
 * Put Company by Id
 *
 * @param id Id of the company
 * @param model CompanyModel
 * @param tenantId Optional tenantId override
 * @param partnerId Optional partnerId override
 * @returns CompanyDtoApiResponse
 */
export const putCompany = async (id: string, model: CompanyModel, tenantId?: string, partnerId?: string) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).put<CompanyDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/company/' + id,
    model
  );
};

// Get Customer Contacts
export const getCustomerContacts = async (
  params?: GetContactQueryParams,
  ownerTenantId?: string,
  ownerPartnerId?: string
) => {
  return GetAxiosInstance(undefined, ownerTenantId, ownerPartnerId).get<ContactDto[]>(
    ServiceLocation.relationshipmanagement + '/contact',
    { params }
  );
};

// Get Departments
export const getDepartments = async () => {
  return GetAxiosInstance().get<DepartmentDto[]>(ServiceLocation.relationshipmanagement + '/department');
};

// Get Contact By Id
export const getContactById = async (contactId: string, params?: GetContactByidQueryParams) => {
  return GetAxiosInstance().get<ContactDto>(ServiceLocation.relationshipmanagement + '/contact/' + contactId, {
    params
  });
};

// Post Contact
export const postContact = async (params: ContactModel) => {
  return GetAxiosInstance().post(ServiceLocation.relationshipmanagement + '/contact', params);
};

// Put Contact
export const putContact = async (params: ContactModel, contactId: string) => {
  return GetAxiosInstance().put(ServiceLocation.relationshipmanagement + '/contact/' + contactId, params);
};

// Get Customer Sites
export const getCustomerSites = async (tenantId?: string, partnerId?: string, params?: GetSiteQueryParams) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).get<SiteDto[]>(
    ServiceLocation.relationshipmanagement + '/site',
    { params }
  );
};

// Get Site Types
export const getSiteTypes = async (params?: GetSiteTypeQueryParams) => {
  return GetAxiosInstance().get<SiteTypeDto[]>(ServiceLocation.relationshipmanagement + '/sitetype', { params });
};

// Get Site Statuses
export const getSiteStatuses = async (params?: GetSiteStatusQueryParams) => {
  return GetAxiosInstance().get<SiteStatusDto[]>(ServiceLocation.relationshipmanagement + '/sitestatus', { params });
};

// Get Site By Id
export const getSiteById = async (
  siteId: string,
  tenantId?: string,
  partnerId?: string,
  params?: GetSiteByidQueryParams
) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).get<SiteDto>(
    ServiceLocation.relationshipmanagement + '/site/' + siteId,
    { params }
  );
};

export const getSite = async (siteId: string, params?: GetSiteByidQueryParams) => {
  return GetAxiosInstance().get<SiteDto>(ServiceLocation.relationshipmanagement + '/site/' + siteId, { params });
};

// Post Site
export const postSite = async (model: SiteModel, tenantId?: string, partnerId?: string) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).post(ServiceLocation.relationshipmanagement + '/site', model);
};

/**
 * Put Site by id
 *
 * @param id Id of the site
 * @param model SiteModel
 * @param tenantId Optional tenantId override
 * @param partnerId Optional partnerId override
 * @returns SiteDtoApiResponse
 */
export const putSite = async (id: string, model: SiteModel, tenantId?: string, partnerId?: string) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).put<SiteDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/site/' + id,
    model
  );
};

/**
 * Get General Config Profiles
 * @param params GetGeneralConfigProfileQueryParams
 * @returns GeneralConfigProfileDto[]
 */
export const getGeneralConfigProfiles = async (params?: GetGeneralConfigProfileQueryParams) => {
  return GetAxiosInstance().get<GeneralConfigProfileDto[]>(
    ServiceLocation.relationshipmanagement + '/GeneralConfigProfile',
    { params }
  );
};

/**
 * Get General Config Profile By Id
 * @param configId Id of the General Config Profile
 * @param params GetGeneralConfigProfileByidQueryParams
 * @returns GeneralConfigProfileDto
 */
export const getGeneralConfigProfileById = async (
  configId: string,
  params?: GetGeneralConfigProfileByidQueryParams
) => {
  return GetAxiosInstance().get<GeneralConfigProfileDto>(
    ServiceLocation.relationshipmanagement + '/GeneralConfigProfile/' + configId,
    { params }
  );
};

/**
 * Post General Config Profile
 * @param model GeneralConfigProfileModel
 * @returns GeneralConfigProfileDtoApiResponse
 */
export const postGeneralConfigProfile = async (model: GeneralConfigProfileModel) => {
  return GetAxiosInstance().post<GeneralConfigProfileDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/GeneralConfigProfile',
    model
  );
};

/**
 * Put General Config Profile
 * @param profileId Id of the General Config Profile to update
 * @param model GeneralConfigProfileModel
 * @returns GeneralConfigProfileDtoApiResponse
 */
export const putGeneralConfigProfile = async (profileId: string, model: GeneralConfigProfileModel) => {
  return GetAxiosInstance().put<GeneralConfigProfileDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/GeneralConfigProfile/' + profileId,
    model
  );
};

/**
 * Get Printing Types
 * @param params GetPrintingTypeQueryParams
 * @returns PrintingTypeDto[]
 */
export const getPrintingTypes = async (params?: GetPrintingTypeQueryParams) => {
  return GetAxiosInstance().get<PrintingTypeDto[]>(ServiceLocation.relationshipmanagement + '/PrintingType', {
    params
  });
};

/**
 * Get Printing Defaults
 * @param params GetPrintingDefaultQueryParams
 * @returns PrintingDefaultDto[]
 */
export const getPrintingDefaults = async (params?: GetPrintingDefaultQueryParams) => {
  return GetAxiosInstance().get<PrintingDefaultDto[]>(ServiceLocation.relationshipmanagement + '/PrintingDefault', {
    params
  });
};

/**
 *  Put Printing Default
 * @param model PrintingDefaultModel
 * @returns PrintingDefaultDtoApiResponse
 */
export const putPrintingDefault = async (id: string, models: PrintingDefaultModel) => {
  return GetAxiosInstance().put<PrintingDefaultDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/PrintingDefault/' + id,
    models
  );
};

/**
 * Bulk Put Printing Defaults
 * @param model PrintingDefaultModelBulkItemModel
 * @returns PrintingDefaultDtoApiResponseListApiResponse
 */
export const bulkPutPrintingDefaults = async (models: PrintingDefaultModelBulkItemModel[]) => {
  return GetAxiosInstance().put<PrintingDefaultDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/PrintingDefault',
    models
  );
};

/**
 * Bulk Delete Printing Defaults
 * @param models PrintingDefaultModelBulkItemModel[]
 * @returns PrintingDefaultDtoApiResponseListApiResponse
 */
export const bulkDeletePrintingDefaults = async (models: PrintingDefaultModelBulkItemModel[]) => {
  const reqBody: AxiosRequestConfig<PrintingDefaultModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<PrintingDefaultDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/PrintingDefault',
    reqBody
  );
};

/**
 * Get Sales Order Cut Off Profiles
 * @param params GetSalesOrderCutOffProfileQueryParams
 * @returns SalesOrderCutOffProfileDto[]
 */
export const getSalesOrderCutOffProfiles = async (params?: GetSalesOrderCutOffProfileQueryParams) => {
  return GetAxiosInstance().get<SalesOrderCutOffProfileDto[]>(
    ServiceLocation.relationshipmanagement + '/SalesOrderCutOffProfile',
    { params }
  );
};

/**
 * Get Sales Order Cut Off Profile by Id
 * @param id Id of the Sales Order Cut Off Profile
 * @param params GetSalesOrderCutOffProfileByidQueryParams
 * @returns SalesOrderCutOffProfileDto
 */
export const getSalesOrderCutOffProfileById = async (
  id: string,
  params?: GetSalesOrderCutOffProfileByidQueryParams
) => {
  return GetAxiosInstance().get<SalesOrderCutOffProfileDto>(
    ServiceLocation.relationshipmanagement + '/SalesOrderCutOffProfile/' + id,
    { params }
  );
};

/**
 * Get Order Types
 * @param params GetOrderTypeQueryParams
 * @returns OrderTypeDto[]
 */
export const getOrderTypes = async (params?: GetOrderTypeQueryParams) => {
  return GetAxiosInstance().get<OrderTypeDto[]>(ServiceLocation.relationshipmanagement + '/OrderType', { params });
};

/**
 * Get Order Type Cut Offs
 * @param params GetOrderTypeCutOffQueryParams
 * @returns OrderTypeCutOffDto[]
 */
export const getOrderTypeCutOffs = async (params?: GetOrderTypeCutOffQueryParams) => {
  return GetAxiosInstance().get<OrderTypeCutOffDto[]>(ServiceLocation.relationshipmanagement + '/OrderTypeCutOff', {
    params
  });
};

/**
 * Bulk Put Order Type Cut Offs
 * @param models OrderTypeCutOffModelBulkItemModel[]
 * @returns OrderTypeCutOffDtoApiResponseListApiResponse
 */
export const bulkPutOrderTypeCutOffs = async (models: OrderTypeCutOffModelBulkItemModel[]) => {
  return GetAxiosInstance().put<OrderTypeCutOffDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/OrderTypeCutOff',
    models
  );
};

/**
 * Bulk Delete Order Type Cut Offs
 * @param models OrderTypeCutOffModelBulkItemModel[]
 * @returns OrderTypeCutOffDtoApiResponseListApiResponse
 */
export const bulkDeleteOrderTypeCutOffs = async (models: OrderTypeCutOffModelBulkItemModel[]) => {
  const reqBody: AxiosRequestConfig<OrderTypeCutOffModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<OrderTypeCutOffDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/OrderTypeCutOff',
    reqBody
  );
};

/**
 * Post Sales Order Cut Off Profile
 * @param model SalesOrderCutOffProfileModel
 * @returns SalesOrderCutOffProfileDtoApiResponse
 */
export const postSalesOrderCutOffProfile = async (model: SalesOrderCutOffProfileModel) => {
  return GetAxiosInstance().post<SalesOrderCutOffProfileDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/SalesOrderCutOffProfile',
    model
  );
};

/**
 * Put Sales Order Cut Off Profile
 * @param id Id of the Sales Order Cut Off Profile
 * @param model SalesOrderCutOffProfileModel
 * @returns SalesOrderCutOffProfileDtoApiResponse
 */
export const putSalesOrderCutOffProfile = async (id: string, model: SalesOrderCutOffProfileModel) => {
  return GetAxiosInstance().put<SalesOrderCutOffProfileDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/SalesOrderCutOffProfile/' + id,
    model
  );
};

/**
 * Get Operational Profiles
 * @param params GetOperationalProfileQueryParams
 * @returns OperationalProfileDto[]
 */
export const getOperationalProfiles = async (params?: GetOperationalProfileQueryParams) => {
  return GetAxiosInstance().get<OperationalProfileDto[]>(
    ServiceLocation.relationshipmanagement + '/OperationalProfile',
    { params }
  );
};

/**
 * Get Operational Profile by Id
 * @param id Id of the Operational Profile
 * @param params GetOperationalProfileByidQueryParams
 * @returns OperationalProfileDto
 */
export const getOperationalProfileById = async (id: string, params?: GetOperationalProfileByidQueryParams) => {
  return GetAxiosInstance().get<OperationalProfileDto>(
    ServiceLocation.relationshipmanagement + '/OperationalProfile/' + id,
    { params }
  );
};

/**
 * Post Operational Profile
 * @param model OperationalProfileModel
 * @returns OperationalProfileDtoApiResponse
 */
export const postOperationalProfile = async (model: OperationalProfileModel) => {
  return GetAxiosInstance().post<OperationalProfileDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/OperationalProfile',
    model
  );
};

/**
 * Put Operational Profile
 * @param id Id of the Operational Profile
 * @param model OperationalProfileModel
 * @returns OperationalProfileDtoApiResponse
 */
export const putOperationalProfile = async (id: string, model: OperationalProfileModel) => {
  return GetAxiosInstance().put<OperationalProfileDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/OperationalProfile/' + id,
    model
  );
};

/**
 * Get Unit Code Profiles
 * @param params GetUnitCodeProfileQueryParams
 * @returns UnitCodeProfileDto[]
 */
export const getUnitCodeProfiles = async (params?: GetUnitCodeProfileQueryParams) => {
  return GetAxiosInstance().get<UnitCodeProfileDto[]>(ServiceLocation.relationshipmanagement + '/UnitCodeProfile', {
    params
  });
};

/**
 * Get Operational Profile Unit Code Profiles
 * @param params GetOperationalProfileUnitCodeProfileQueryParams
 * @returns OperationalProfileUnitCodeProfileDto[]
 */
export const getOperationalProfileUnitCodeProfiles = async (
  params?: GetOperationalProfileUnitCodeProfileQueryParams
) => {
  return GetAxiosInstance().get<OperationalProfileUnitCodeProfileDto[]>(
    ServiceLocation.relationshipmanagement + '/OperationalProfileUnitCodeProfile',
    { params }
  );
};

/**
 * Bulk Put Operational Profile Unit Code Profiles
 * @param models OperationalProfileUnitCodeProfileModelBulkItemModel[]
 * @returns OperationalProfileUnitCodeProfileDtoApiResponseListApiResponse
 */
export const bulkPutOperationalProfileUnitCodeProfiles = async (
  models?: OperationalProfileUnitCodeProfileModelBulkItemModel[]
) => {
  return GetAxiosInstance().put<OperationalProfileUnitCodeProfileDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/OperationalProfileUnitCodeProfile',
    models
  );
};

/**
 * Bulk Delete Operational Profile Unit Code Profiles
 * @param models OperationalProfileUnitCodeProfileModelBulkItemModel[]
 * @returns OperationalProfileUnitCodeProfileDtoApiResponseListApiResponse
 */
export const bulkDeleteOperationalProfileUnitCodeProfiles = async (
  models?: OperationalProfileUnitCodeProfileModelBulkItemModel[]
) => {
  const reqBody: AxiosRequestConfig<OperationalProfileUnitCodeProfileModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<OperationalProfileUnitCodeProfileDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/OperationalProfileUnitCodeProfile',
    reqBody
  );
};

/**
 * Get Surcharge Codes
 * @param params GetSurchargeTypeQueryParams
 * @returns SurchargeTypeDto[]
 */
export const getSurchargeCodes = async (params?: GetSurchargeTypeQueryParams) => {
  return GetAxiosInstance().get<SurchargeTypeDto[]>(ServiceLocation.relationshipmanagement + '/SurchargeType', {
    params
  });
};

/**
 * Get Default Surcharges
 * @param params GetDefaultSurchargeQueryParams
 * @returns DefaultSurchargeDto[]
 */
export const getDefaultSurcharges = async (partnerId?: string, params?: GetDefaultSurchargeQueryParams) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<DefaultSurchargeDto[]>(
    ServiceLocation.relationshipmanagement + '/DefaultSurcharge',
    {
      params
    }
  );
};

/**
 * Get Hazardous Goods Profiles
 * @param params GetHazardousGoodsProfileQueryParams
 * @returns HazardousGoodsProfileDto[]
 */
export const getHazardousProfiles = async (params?: GetHazardousGoodsProfileQueryParams) => {
  return GetAxiosInstance().get<HazardousGoodsProfileDto[]>(
    ServiceLocation.relationshipmanagement + '/HazardousGoodsProfile',
    { params }
  );
};

/**
 * Get Hazardous Goods Profile by Id
 * @param id Id of the Hazardous Goods Profile
 * @param params GetHazardousGoodsProfileByidQueryParams
 * @returns HazardousGoodsProfileDto
 */
export const getHazardousProfileById = async (id: string, params?: GetHazardousGoodsProfileByidQueryParams) => {
  return GetAxiosInstance().get<HazardousGoodsProfileDto>(
    ServiceLocation.relationshipmanagement + '/HazardousGoodsProfile/' + id,
    { params }
  );
};

/**
 * Post Hazardous Goods Profile
 * @param model HazardousGoodsProfileModel
 * @returns HazardousGoodsProfileDtoApiResponse
 */
export const postHazardousProfile = async (model: HazardousGoodsProfileModel) => {
  return GetAxiosInstance().post<HazardousGoodsProfileDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/HazardousGoodsProfile',
    model
  );
};

/**
 * Put Hazardous Goods Profile
 * @param id Id of the Hazardous Goods Profile
 * @param model HazardousGoodsProfileModel
 * @returns HazardousGoodsProfileDtoApiResponse
 */
export const putHazardousProfile = async (id: string, model: HazardousGoodsProfileModel) => {
  return GetAxiosInstance().put<HazardousGoodsProfileDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/HazardousGoodsProfile/' + id,
    model
  );
};

/**
 * Get Hazardous Goods Classes
 * @param params GetHazardousGoodsClassQueryParams
 * @returns HazardousGoodsClassDto[]
 */
export const getHazardousGoodsClasses = async (params?: GetHazardousGoodsClassQueryParams) => {
  return GetAxiosInstance().get<HazardousGoodsClassDto[]>(
    ServiceLocation.relationshipmanagement + '/HazardousGoodsClass',
    { params }
  );
};

/**
 * Get Available Hazardous Goods Classes
 * @param params GetAvailableHazardousGoodsClassQueryParams
 * @returns AvailableHazardousGoodsClassDto[]
 */
export const getAvailableHazardousGoodsClasses = async (params?: GetAvailableHazardousGoodsClassQueryParams) => {
  return GetAxiosInstance().get<AvailableHazardousGoodsClassDto[]>(
    ServiceLocation.relationshipmanagement + '/AvailableHazardousGoodsClass',
    { params }
  );
};

/**
 * Bulk Put Available Hazardous Goods Classes
 * @param models AvailableHazardousGoodsClassModelBulkItemModel[]
 * @returns AvailableHazardousGoodsClassDtoApiResponseListApiResponse
 */
export const bulkPutAvailableHazardousGoodsClasses = async (
  models: AvailableHazardousGoodsClassModelBulkItemModel[]
) => {
  return GetAxiosInstance().put<AvailableHazardousGoodsClassDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/AvailableHazardousGoodsClass',
    models
  );
};

/**
 * Bulk Delete Available Hazardous Goods Classes
 * @param models AvailableHazardousGoodsClassModelBulkItemModel[]
 * @returns AvailableHazardousGoodsClassDtoApiResponseListApiResponse
 */
export const bulkDeleteAvailableHazardousGoodsClasses = async (
  models: AvailableHazardousGoodsClassModelBulkItemModel[]
) => {
  const reqBody: AxiosRequestConfig<AvailableHazardousGoodsClassModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<AvailableHazardousGoodsClassDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/AvailableHazardousGoodsClass',
    reqBody
  );
};

/**
 * Get Unit Of Measures
 * @param params GetUnitOfMeasureQueryParams
 * @returns UnitOfMeasureDto[]
 */
export const getUnitOfMeasures = async (params?: GetUnitOfMeasureQueryParams) => {
  return GetAxiosInstance().get<UnitOfMeasureDto[]>(ServiceLocation.relationshipmanagement + '/UnitOfMeasure', {
    params
  });
};

/**
 * Get Hazardous Measures
 * @param params GetHazardousMeasureQueryParams
 * @returns HazardousMeasureDto[]
 */
export const getHazardousMeasures = async (params?: GetHazardousMeasureQueryParams) => {
  return GetAxiosInstance().get<HazardousMeasureDto[]>(ServiceLocation.relationshipmanagement + '/HazardousMeasure', {
    params
  });
};

/**
 * Bulk Put Hazardous Measures
 * @param models HazardousMeasureModelBulkItemModel[]
 * @returns HazardousMeasureDtoApiResponseListApiResponse
 */
export const bulkPutHazardousMeasures = async (models: HazardousMeasureModelBulkItemModel[]) => {
  return GetAxiosInstance().put<HazardousMeasureDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/HazardousMeasure',
    models
  );
};

/**
 * Bulk Delete Hazardous Measures
 * @param models HazardousMeasureModelBulkItemModel[]
 * @returns HazardousMeasureDtoApiResponseListApiResponse
 */
export const bulkDeleteHazardousMeasures = async (models: HazardousMeasureModelBulkItemModel[]) => {
  const reqBody: AxiosRequestConfig<HazardousMeasureModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<HazardousMeasureDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/HazardousMeasure',
    reqBody
  );
};

/**
 * Get Unit Categories
 * @param params GetUnitCategoryQueryParams
 * @returns UnitCategoryDto[]
 */
export const getUnitCategories = async (params?: GetUnitCategoryQueryParams) => {
  return GetAxiosInstance().get<UnitCategoryDto[]>(ServiceLocation.relationshipmanagement + '/UnitCategory', {
    params
  });
};

/**
 * Get Hazardous Types
 * @param params GetHazardousTypeQueryParams
 * @returns HazardousTypeDto[]
 */
export const getHazardousTypes = async (params?: GetHazardousTypeQueryParams) => {
  return GetAxiosInstance().get<HazardousTypeDto[]>(ServiceLocation.relationshipmanagement + '/HazardousType', {
    params
  });
};

/**
 * Bulk Put Hazardous Types
 * @param models HazardousTypeModelBulkItemModel[]
 * @returns HazardousTypeDtoApiResponseListApiResponse
 */
export const bulkPutHazardousTypes = async (models: HazardousTypeModelBulkItemModel[]) => {
  return GetAxiosInstance().put<HazardousTypeDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/HazardousType',
    models
  );
};

/**
 * Bulk Delete Hazardous Types
 * @param models HazardousTypeModelBulkItemModel[]
 * @returns HazardousTypeDtoApiResponseListApiResponse
 */
export const bulkDeleteHazardousTypes = async (models: HazardousTypeModelBulkItemModel[]) => {
  const reqBody: AxiosRequestConfig<HazardousTypeModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<HazardousTypeDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/HazardousType',
    reqBody
  );
};

/**
 * Bulk Patch Customer Profiles
 * @param models CustomerModelBulkItemModel[]
 * @returns CustomerDtoApiResponseListApiResponse
 */
export const bulkPatchCustomerProfiles = async (
  models: CustomerModelBulkItemModel[],
  params?: PatchCustomerQueryParams
) => {
  return GetAxiosInstance().patch<CustomerDtoApiResponseListApiResponse>(
    ServiceLocation.relationshipmanagement + '/Customer',
    models,
    { params }
  );
};

/**
 * Get sales order configuration by customer id.
 * @param customerId
 * @param params
 * @returns
 */
export const getRelationshipManagementSalesOrderConfiguration = (
  customerId: string,
  params?: GetSalesOrderConfigurationBycustomerIdQueryParams
) => {
  return GetAxiosInstance().get<SalesOrderConfigurationDto>(
    `${ServiceLocation.relationshipmanagement}/SalesOrderConfiguration/${customerId}`,
    { params }
  );
};

/**
 * Get Sales Order Reference Types
 * @param params GetSalesOrderReferenceTypeQueryParams
 * @returns SalesOrderReferenceTypeDto[]
 */
export const getSalesOrderReferenceTypes = async (params?: GetSalesOrderReferenceTypeQueryParams) => {
  return GetAxiosInstance().get<SalesOrderReferenceTypeDto[]>(
    ServiceLocation.relationshipmanagement + '/SalesOrderReferenceType',
    { params }
  );
};

export interface RelationshipBulkModel {
  operationId: string;
  id: string;
  model: RelationshipDto;
}

export const bulkPutRelationship = async (models: RelationshipBulkModel[]) => {
  return GetAxiosInstance().put<RelationshipDtoApiResponse>(
    ServiceLocation.relationshipmanagement + '/relationship',
    models
  );
};
