import { useVigoToast } from './useVigoToast';
import { useMutation } from '@tanstack/react-query';
import { useScreenshot } from 'use-react-screenshot';
import { getUIComponents, updateUIComponentById } from 'src/services/systemconfig';
import { UicomponentDto } from '../../models/account';
import { useAppSelector } from 'src/store/baseHooks';

/**
 *  Hook to handle capturing preview images of UI Flow Components.
 *
 */
export const useGenerateUIFlowPreview = () => {
  const [, takeScreenshot] = useScreenshot();
  const { vigoToast } = useVigoToast({});

  const isItemView = useAppSelector(state => state.uiflow.isItemView);
  const activeComponentName = useAppSelector(state => state.uiflow.activeTabState.activeComponentName);

  const saveImage = useMutation(({ image, component }: { image: string; component: UicomponentDto }) => {
    return updateUIComponentById(component.id ?? '', {
      ...component,
      previewImage: image,
      displayName: component.displayName ?? '',
      name: component.name ?? ''
    });
  });

  const generatePreview = async () => {
    const el = document.querySelector('.capture[data-active="true"]');
    const name = isItemView ? activeComponentName : el?.getAttribute('data-name');
    const { data } = await getUIComponents({ filter: `component li '%${name}%'` });

    if (data?.length > 0) {
      data.forEach((component: UicomponentDto) => {
        takeScreenshot(el).then((image: any) => {
          if (image && name && component) {
            vigoToast.promise(
              `Updating ${name} preview`,
              `Successfully updated ${name} preview`,
              `Unable to capture ${name} preview`,
              false,
              saveImage.mutateAsync({ image, component })
            );
          }
        });
      });
    } else {
      vigoToast.error('Unable to capture component preview');
    }
  };

  return { generatePreview, isLoading: saveImage.isLoading };
};
