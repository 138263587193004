import {
  AdminCentreViewDto,
  BaseModel,
  BooleanApiResponse,
  DtoDto,
  DtoPropertyDto,
  GetDtoPropertyQueryParams,
  GetDtoQueryParams,
  GetMasterDataChildQueryParams,
  GetMasterDataGroupQueryParams,
  GetMasterDataQueryParams,
  GetPropertyTypeQueryParams,
  GetServiceQueryParams,
  GetUicomponentByidQueryParams,
  GetUicomponentHelpStepQueryParams,
  GetUicomponentHintQueryParams,
  GetUicomponentQueryParams,
  GetUicomponentTypeQueryParams,
  GetUiflowBuilderByuiflowIdQueryParams,
  GetUiflowByidQueryParams,
  GetUiflowQueryParams,
  GetUiflowStepQueryParams,
  GetUiflowTypeQueryParams,
  GetUigroupItemQueryParams,
  GetUigroupQueryParams,
  MasterDataBuilderDto,
  MasterDataChildDto,
  MasterDataColumnDto,
  MasterDataDto,
  PropertyTypeDto,
  ServiceDto,
  UicomponentDto,
  UicomponentDtoApiResponse,
  UicomponentHelpStepDto,
  UicomponentHelpStepDtoApiResponse,
  UicomponentHelpStepModel,
  UicomponentHintDto,
  UicomponentHintDtoApiResponse,
  UicomponentHintModel,
  UicomponentModel,
  UicomponentTypeDto,
  UiflowBuilderDto,
  UiflowDto,
  UiflowDtoApiResponse,
  UiflowModel,
  UiflowStepDto,
  UiflowStepDtoApiResponse,
  UiflowStepModel,
  UiflowTypeDto,
  UigroupDto,
  UigroupItemDto
} from 'src/models/systemconfig';
import { MasterDataFetchCustomQueryParams } from 'src/types/apps/masterdataTypes';
import { GetAxiosInstance } from '../baseService';
import { ConvertServiceLocationToEnum, ServiceLocation } from '../serviceLocationsAxios';
import { ExportModel } from 'src/models/account';
import { AxiosRequestConfig } from 'axios';

// get the groups for masterdata
export const getMasterDataGroups = async (params: GetMasterDataGroupQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance().get<MasterDataDto[]>(ServiceLocation.systemconfig + '/MasterDataGroup', { params });
};

// get the list of tables in masterdata
export const getMasterDataTables = async (params: GetMasterDataQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance().get<MasterDataDto[]>(ServiceLocation.systemconfig + '/MasterData', { params });
};

// get the columns for specific master data table
export const getMasterDataTableColumns = async (params: MasterDataFetchCustomQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance().get<MasterDataColumnDto[]>(ServiceLocation.systemconfig + '/MasterDataColumn', { params });
};

// get the child tables for a specific master data table
export const getMasterDataChildTables = async (params: GetMasterDataChildQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance().get<MasterDataChildDto[]>(ServiceLocation.systemconfig + '/MasterDataChild', { params });
};

// get Dto Property
export const getDtoPropertiesByDtoId = async (params: GetDtoPropertyQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance().get<DtoPropertyDto[]>(ServiceLocation.systemconfig + '/DtoProperty', { params });
};

// get Property Types
export const getPropertyTypes = async (params: GetPropertyTypeQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance().get<PropertyTypeDto[]>(ServiceLocation.systemconfig + '/PropertyType', { params });
};

// get Dto By Id
export const getDtoById = async (params: GetDtoQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance().get<DtoDto>(ServiceLocation.systemconfig + '/Dto', { params });
};

// get ServiceDtos
export const getServices = async (params: GetServiceQueryParams) => {
  return GetAxiosInstance().get<ServiceDto[]>(ServiceLocation.systemconfig + '/Service', { params });
};

// build MasterData Object for UI
export const getMasterDataObject = async (masterDataTableId: string) => {
  return GetAxiosInstance().get<MasterDataBuilderDto>(
    ServiceLocation.systemconfig + '/MasterDataBuilder/' + masterDataTableId
  );
};

// get the ui groups
export const getUIGroups = async (params: GetUigroupQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance().get<UigroupDto[]>(ServiceLocation.systemconfig + '/UIGroup', { params });
};

// get the luigroup items
export const getUIGroupItems = async (params: GetUigroupItemQueryParams) => {
  return GetAxiosInstance().get<UigroupItemDto[]>(ServiceLocation.systemconfig + '/UIGroupItem', { params });
};

/**
 * Get the admin centre view
 * @returns AdminCentreViewDto
 */
export const getAdminCentreView = async (moduleId: string) => {
  return GetAxiosInstance().get<AdminCentreViewDto>(ServiceLocation.systemconfig + '/AdminCentre/' + moduleId);
};

/**
 * Request grid export
 *
 * @param params ExportModel
 * @param exportService The service name i.e. 'salesorder'
 * @param exportType The export type i.e. 'Surcharge'
 * @returns StringConstructor[]
 */
export const postExportRequest = (params?: ExportModel, exportService?: string, exportType?: string) => {
  const url = ConvertServiceLocationToEnum(exportService) + '/' + exportType + '/export';

  return GetAxiosInstance().post<StringConstructor[]>(url, {
    filter: params?.filter,
    sort: params?.sort,
    notifyOnCompletion: params?.notifyOnCompletion,
    showDeleted: params?.showDeleted,
    ignoreOwner: params?.ignoreOwner,
    columns: params?.columns
  });
};

/**
 * Get all Uiflow objects
 *
 * @param none
 * @returns UiflowDto
 */
export const getAllUiFlow = async (params: GetUiflowQueryParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get<UiflowDto[]>(
    `${ConvertServiceLocationToEnum('systemconfig')}/Uiflow/`,
    { params }
  );
};

/**
 * Get Uiflow object by id
 *
 * @param id Id of the Uiflow
 * @param params GetUiflowBuilderByuiflowIdQueryParams
 * @returns UiflowBuilderDto
 */
export const getUiFlowObject = async (id: string, params?: GetUiflowBuilderByuiflowIdQueryParams) => {
  return GetAxiosInstance().get<UiflowBuilderDto>(`${ServiceLocation.systemconfig}/UiflowBuilder/${id}`, { params });
};

/**
 * Add a new Uiflow
 *
 * @param params UiflowModel
 * @returns UiflowDtoApiResponse
 */
export const postUiFlow = async (model: UiflowModel) => {
  return GetAxiosInstance().post<UiflowDtoApiResponse>(`${ServiceLocation.systemconfig}/Uiflow`, model);
};

/**
 * Update a Uiflow by id
 *
 * @param id Id of the Uiflow
 * @param model UiflowModel
 * @returns UiflowDtoApiResponse
 */
export const updateUiFlow = async (id: string, model: UiflowModel) => {
  return GetAxiosInstance().put<UiflowDtoApiResponse>(`${ServiceLocation.systemconfig}/Uiflow/${id}`, model);
};

/**
 * Delete a Uiflow by id
 *
 * @param id Id of the Uiflow
 * @param params model
 * @returns BooleanApiResponse
 */
export const deleteUiFlow = async (id: string, model: BaseModel) => {
  const reqBody: AxiosRequestConfig<BaseModel> = {
    data: model
  };

  return GetAxiosInstance().delete<BooleanApiResponse>(`${ServiceLocation.systemconfig}/Uiflow/${id}`, reqBody);
};

/**
 * Get all UI Flow Types
 *
 * @params params GetUiFlowTypeParams
 * @returns UiflowTypeDto[]
 */
export const getUiFlowTypes = async (params?: GetUiflowTypeQueryParams) => {
  return GetAxiosInstance().get<UiflowTypeDto[]>(`${ServiceLocation.systemconfig}/UiflowType`, {
    params
  });
};

/**
 * Get UI Flow by id
 *
 * @param id Id of the Uiflow
 * @params params GetUiflowByidQueryParams
 * @returns UiflowDto
 */
export const getUiFlow = async (id: string, params?: GetUiflowByidQueryParams) => {
  return GetAxiosInstance().get<UiflowDto>(`${ServiceLocation.systemconfig}/Uiflow/${id}`, { params });
};

/**
 * Get all ui components
 *
 * @param params GetUicomponentQueryParams
 * @returns UicomponentDto[]
 */
export const getUIComponents = async (params?: GetUicomponentQueryParams) => {
  return GetAxiosInstance().get<UicomponentDto[]>(`${ServiceLocation.systemconfig}/Uicomponent`, {
    params
  });
};

/**
 * Get ui component by id
 *
 * @param id Id of the Uicomponent
 * @param params GetUicomponentByidQueryParams
 * @returns UicomponentDto
 */
export const getUIComponentById = (id: string, params?: GetUicomponentByidQueryParams) => {
  return GetAxiosInstance().get<UicomponentDto>(`${ServiceLocation.systemconfig}/Uicomponent/${id}`, { params });
};

/**
 * Update a Uicomponent by id
 *
 * @param id Id of the Uicomponent
 * @param model UicomponentModel
 * @returns UicomponentDtoApiResponse
 */
export const updateUIComponentById = async (id: string, model: UicomponentModel) => {
  return GetAxiosInstance().put<UicomponentDtoApiResponse>(`${ServiceLocation.systemconfig}/Uicomponent/${id}`, model);
};

/**
 * Get UI component types
 *
 * @param params GetUicomponentTypeQueryParams
 * @returns UicomponentType[]
 */
export const getUIComponentTypes = async (params?: GetUicomponentTypeQueryParams) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get<
    UicomponentTypeDto[]
  >(`${ServiceLocation.systemconfig}/UicomponentType`, { params });
};

/**
 * Get all Uiflow steps
 *
 * @param params GetUiflowStepQueryParams
 * @returns UiflowStepDto[]
 */
export const getUiFlowSteps = async (params?: GetUiflowStepQueryParams) => {
  return GetAxiosInstance().get<UiflowStepDto[]>(`${ServiceLocation.systemconfig}/UiflowStep`, {
    params
  });
};

/**
 * Add a new Ui flow step
 *
 * @param model UiflowStepModel
 * @returns UiflowStepDtoApiResponse
 */
export const postUiFlowStep = async (model: UiflowStepModel) => {
  return GetAxiosInstance().post<UiflowStepDtoApiResponse>(`${ServiceLocation.systemconfig}/UiflowStep`, model);
};

/**
 * Delete a Ui flow step
 *
 * @param id Id of the UiflowStep
 * @param model BaseModel
 * @returns BooleanApiResponse
 */
export const deleteUiFlowStep = async (id: string, model: BaseModel) => {
  const reqBody: AxiosRequestConfig<BaseModel> = {
    data: model
  };

  return GetAxiosInstance().delete<BooleanApiResponse>(`${ServiceLocation.systemconfig}/UiflowStep/${id}`, reqBody);
};

/**
 * Get UI component hints
 *
 * @param params GetUicomponentHintQueryParams
 * @returns UicomponentHintDto[]
 */
export const getUicomponentHints = async (params?: GetUicomponentHintQueryParams) => {
  return GetAxiosInstance().get<UicomponentHintDto[]>(`${ServiceLocation.systemconfig}/UicomponentHint`, { params });
};

/**
 * Post new UI component hint
 *
 * @param model UicomponentHintModel
 * @returns UicomponentHintDtoApiResponse
 */
export const postUicomponentHint = (model: UicomponentHintModel) => {
  return GetAxiosInstance().post<UicomponentHintDtoApiResponse>(
    `${ServiceLocation.systemconfig}/UicomponentHint`,
    model
  );
};

/**
 * Delete UI component hint by id
 *
 * @param id Id of the UicomponentHint
 * @param model BaseModel
 * @returns BooleanApiResponse
 */
export const deleteUicomponentHintById = (id: string, model: BaseModel) => {
  const reqBody: AxiosRequestConfig<BaseModel> = {
    data: model
  };

  return GetAxiosInstance().delete<BooleanApiResponse>(
    `${ServiceLocation.systemconfig}/UicomponentHint/${id}`,
    reqBody
  );
};

/**
 * Get UI component help steps
 *
 * @param params GetUicomponentHelpStepQueryParams
 * @returns UicomponentHelpStepDto[]
 */
export const getUicomponentHelpStep = async (params?: GetUicomponentHelpStepQueryParams) => {
  return GetAxiosInstance().get<UicomponentHelpStepDto[]>(`${ServiceLocation.systemconfig}/UicomponentHelpStep`, {
    params
  });
};

/**
 * Post new UI component help step
 *
 * @param model UicomponentHelpStepModel
 * @returns UicomponentHelpStepDtoApiResponse
 */
export const postUicomponentHelpStep = async (model: UicomponentHelpStepModel) => {
  return GetAxiosInstance().post<UicomponentHelpStepDtoApiResponse>(
    `${ServiceLocation.systemconfig}/UicomponentHelpStep`,
    model
  );
};

/**
 * Delete UI component help step by id
 *
 * @param params DeleteUicomponentHelpStepByIdParams
 * @returns UicomponentHelpStepDtoApiResponse
 */
export const deleteUicomponentHelpStepById = (id: string, model: BaseModel) => {
  const reqBody: AxiosRequestConfig<BaseModel> = {
    data: model
  };

  return GetAxiosInstance().delete<BooleanApiResponse>(
    `${ServiceLocation.systemconfig}/UicomponentHelpStep/${id}`,
    reqBody
  );
};
