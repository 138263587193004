import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  getAttachmentFile,
  getDtoById,
  getAttachmentTypes,
  getEntityTypeAllowedAttachmentTypes,
  getAttachments
} from 'src/services/attachment';
import {
  AttachmentDto,
  AttachmentTypeDto,
  DtoDto,
  EntityTypeAllowedAttachmentTypeDto,
  GetAttachmentQueryParams,
  GetAttachmentTypeQueryParams,
  GetDtoByidQueryParams,
  GetEntityTypeAllowedAttachmentTypeQueryParams
} from 'src/models/attachment';
import { baseQueryFn, QueryProps } from 'src/queries/baseQueryFunctions';

/**
 * Entity Type Allowed Attachment Types query
 */
export const useGetEntityTypeAllowedAttachmentTypesQuery = ({
  enabled = false,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<EntityTypeAllowedAttachmentTypeDto[], GetEntityTypeAllowedAttachmentTypeQueryParams>): UseQueryResult<
  EntityTypeAllowedAttachmentTypeDto[]
> => {
  return useQuery({
    queryKey: queryKeyOverride ?? ['entityTypeAllowedAttachmentTypes'],
    queryFn: () =>
      baseQueryFn({
        serviceFn: () => getEntityTypeAllowedAttachmentTypes(params),
        customResponseModifier
      }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Attachment Types
 */
export const useGetAttachmentTypesQuery = ({
  enabled = false,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<AttachmentTypeDto[], GetAttachmentTypeQueryParams>): UseQueryResult<AttachmentTypeDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ?? ['attachmentTypes'],
    queryFn: () => baseQueryFn({ serviceFn: () => getAttachmentTypes(params), customResponseModifier }),
    enabled,
    initialData,
    onSuccess,
    refetchOnWindowFocus
  });
};

/**
 * Get Attachments query
 */
export const useGetAttachmentsQuery = ({
  enabled = false,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<AttachmentDto[], GetAttachmentQueryParams>): UseQueryResult<AttachmentDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ?? ['attachments'],
    queryFn: () => baseQueryFn({ serviceFn: () => getAttachments(params), customResponseModifier }),
    enabled,
    initialData,
    onSuccess,
    refetchOnWindowFocus
  });
};

/**
 * Get attachment file
 */
export const useGetAttachmentFileQuery = (
  attachmentId: string,
  {
    enabled = false,
    initialData = [],
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<any, {}>
) => {
  return useQuery({
    queryKey: queryKeyOverride ?? ['attachmentFile', customResponseModifier],
    queryFn: () => baseQueryFn({ serviceFn: () => getAttachmentFile(attachmentId, params), customResponseModifier }),
    enabled,
    initialData,
    onSuccess,
    refetchOnWindowFocus
  });
};

/**
 * Get Dto by id
 */
export const useGetDtoByIdQuery = (
  id: string,
  {
    enabled = false,
    initialData = {},
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params = {}
  }: QueryProps<DtoDto, GetDtoByidQueryParams>
): UseQueryResult<DtoDto> => {
  return useQuery({
    queryKey: queryKeyOverride ?? ['attachmentServiceDto', id],
    queryFn: () => baseQueryFn({ serviceFn: () => getDtoById(id, params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};
