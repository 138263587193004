// ** React Imports
import { useState, SyntheticEvent, Fragment, useEffect, useRef } from 'react';

// ** Next Import
import { useRouter } from 'next/router';

// ** MUI Imports
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

// ** Icons Imports
import LogoutVariant from 'mdi-material-ui/LogoutVariant';
import AccountOutline from 'mdi-material-ui/AccountOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// ** Context
import { useAuth } from 'react-oidc-context';

// ** Type Imports
import { Settings } from 'src/@core/context/settingsContext';

import { VigoTypography } from 'src/@vigo/vigo-typography';
import { useQuery } from '@tanstack/react-query';
import { getRolesAsync } from 'src/services/identity';
import { ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper, Stack } from '@mui/material';
import { RoleDto } from 'src/models/account';
import { VigoButton } from 'src/@vigo/vigo-button';
import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';
import { useGetUserProfileQuery } from 'src/queries/services/identity';

interface Props {
  settings: Settings;
  saveSettings: (values: Settings) => void;
}

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}));

const identityQueryParams = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  staleTime: 180000
};

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings, saveSettings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [currentRole, setCurrentRole] = useState<string | undefined>('');
  const [userRoles, setUserRoles] = useState<RoleDto[]>([]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  // ** Hooks
  const router = useRouter();
  const { signoutRedirect, user } = useAuth();
  const { t } = useVigoTranslation();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const loggedInUserId = user?.profile.tenantUserId as string;

  const { data: userData } = useGetUserProfileQuery(loggedInUserId, identityQueryParams);

  const { data: roles } = useQuery({
    queryKey: ['userroles'],
    queryFn: async () => {
      const res = await getRolesAsync();

      return res.data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24
  });
  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, role: any) => {
    setOpen(false);
    setCurrentRole(role.name);
    saveSettings({ ...settings, currentRole: role.id as string });
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url);
    }
    setAnchorEl(null);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('account-configurations');
    saveSettings({ ...settings, currentRole: '' as string });
    signoutRedirect();
    handleDropdownClose();
  };

  useEffect(() => {
    const userRole = roles
      ?.map(role => {
        if (role.id == userData?.userProfile?.defaultRoleId) {
          return role;
        }
      })
      .find(r => {
        if (r != undefined) {
          return r.name;
        }
      });
    setCurrentRole(userRole?.name);
  }, [roles, userData]);

  useEffect(() => {
    if (roles) {
      const userAssignedRoles = roles.filter((role: RoleDto) => userData?.roles?.includes(role.id!));
      setUserRoles(userAssignedRoles);
    }
  }, [userData?.roles, roles]);

  return (
    <Fragment>
      <Avatar
        onClick={handleDropdownOpen}
        sx={{ marginLeft: 2, marginRight: -3, width: 32, height: 32, cursor: 'pointer' }}
        src={userData?.userProfile?.avatar}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { minWidth: 250, marginTop: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}>
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}>
              <Avatar src={userData?.userProfile?.avatar} sx={{ width: '2.5rem', height: '2.5rem' }} />
            </Badge>
            <Box sx={{ display: 'flex', marginLeft: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
              <Stack spacing={1} direction={'row'}>
                <VigoTypography disableTranslation value={userData?.userProfile?.forname} sx={{ fontWeight: 600 }} />
                <VigoTypography disableTranslation value={userData?.userProfile?.surname} sx={{ fontWeight: 600 }} />
              </Stack>

              <ButtonGroup
                sx={{
                  color: 'primary',
                  paddingLeft: '5px',
                  border: '0.5px solid #ffffff',
                  '&:hover': { border: '0.2px #f3f3f3 solid' },
                  display: 'flex',
                  alignItems: 'center'
                }}
                ref={anchorRef}
                variant="outlined"
                aria-label="split button">
                <VigoTypography
                  disableTranslation
                  value={currentRole}
                  onClick={handleToggle}
                  variant={'subtitle2'}
                  sx={{
                    color: 'primary',
                    height: '1.5rem',
                    cursor: 'pointer'
                  }}
                />
                <VigoButton
                  name="menu"
                  value=""
                  startIcon={<ArrowDropDownIcon sx={{ color: 'primary' }} />}
                  size="small"
                  variant="outlined"
                  sx={{ color: 'primary', border: 'none !important', outline: 'none !important' }}
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="menu"
                  onClick={handleToggle}
                />
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" sx={{ minWidth: '160px' }} autoFocusItem>
                          {userRoles.map(role => (
                            <MenuItem key={role.id} onClick={event => handleMenuItemClick(event, role)}>
                              <Box sx={{ display: 'flex' }}>
                                <VigoTypography disableTranslation value={role.description} />
                              </Box>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 1 }} />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/admin/user-profile')}>
          <Box sx={styles}>
            <AccountOutline sx={{ marginRight: 2 }} />
            {t('Profile')}
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
          <LogoutVariant sx={{ marginRight: 2, fontSize: '1.375rem', color: 'text.secondary' }} />
          {t('Logout')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
