import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  ClientDto,
  GetClientQueryParams,
  GetUserByidQueryParams,
  GetUserQueryParams,
  GetUserStateQueryParams,
  UserDto,
  UserListItemDto,
  UserStateDto
} from 'src/models/identity';
import { QueryProps, baseQueryFn } from 'src/queries/baseQueryFunctions';
import { getAllUsersAsync, getClients, getUserByIdAsync, getUserStates } from 'src/services/identity';

/**
 * Clients query
 */
export const useGetClientsQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  refetchOnMount = true,
  staleTime = Infinity,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<ClientDto[], GetClientQueryParams>): UseQueryResult<ClientDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['clients', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getClients(params), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    refetchOnMount,
    staleTime,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * User Profile Query
 */
export const useGetUserProfileQuery = (
  loggedInUserId: string,
  {
    initialData,
    onSuccess = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    staleTime = Infinity,
    params
  }: QueryProps<UserDto, GetUserByidQueryParams> = {}
): UseQueryResult<UserDto> => {
  return useQuery({
    queryKey: queryKeyOverride
      ? queryKeyOverride
      : ['userProfile', loggedInUserId, params || '', staleTime, refetchOnWindowFocus],
    queryFn: () => baseQueryFn({ serviceFn: () => getUserByIdAsync(loggedInUserId), customResponseModifier }),
    enabled: !!loggedInUserId,
    initialData,
    refetchOnWindowFocus,
    staleTime,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Users Query
 */
export const useGetUsersQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride,
  params = {}
}: QueryProps<UserListItemDto[], GetUserQueryParams>): UseQueryResult<UserListItemDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ? queryKeyOverride : ['users', params],
    queryFn: () => baseQueryFn({ serviceFn: () => getAllUsersAsync(), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) })
  });
};

/**
 * Get user by id query
 */
export const useGetUserByIdQuery = (
  id: string,
  {
    enabled = true,
    initialData = {},
    onSuccess = undefined,
    onError = undefined,
    refetchOnWindowFocus = false,
    customResponseModifier = undefined,
    queryKeyOverride,
    params
  }: QueryProps<UserDto, GetUserByidQueryParams>
): UseQueryResult<UserDto> => {
  return useQuery({
    queryKey: queryKeyOverride ?? ['user', id, params || ''],
    queryFn: () =>
      baseQueryFn({ serviceFn: () => getUserByIdAsync(id, params, undefined, false), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};

/**
 * Get user states query
 */
export const useGetUserStatesQuery = ({
  enabled = true,
  initialData = [],
  onSuccess = undefined,
  onError = undefined,
  refetchOnWindowFocus = false,
  customResponseModifier = undefined,
  queryKeyOverride
}: QueryProps<UserStateDto[], GetUserStateQueryParams>): UseQueryResult<UserStateDto[]> => {
  return useQuery({
    queryKey: queryKeyOverride ?? ['userstates'],
    queryFn: async () => baseQueryFn({ serviceFn: () => getUserStates(), customResponseModifier }),
    enabled,
    initialData,
    refetchOnWindowFocus,
    ...(typeof onSuccess === 'function' && { onSuccess: res => onSuccess(res) }),
    ...(typeof onError === 'function' && { onError: (error: Error) => onError(error) })
  });
};
